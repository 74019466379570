import { addSelectors, select } from './components/lib';

import transition from './components/transition'
import nav from './components/nav'
import image from './components/image'
import gallery from './components/gallery'
import bouncy from './components/bouncy'
import link from './components/link'
import projectLink from './components/project-link'
import page from './components/page'

addSelectors({
    '.transition': transition({ pageSelector: 'main' }),
    'nav': nav,
    'img[data-srcset]': image,
    '.gallery': gallery,
    '.bouncy': bouncy,
    'a': link,
    '.project-link': projectLink,
    // 'main': page
})

select(document.body)