const listeners = [];
const trigger = () => listeners.forEach(f => f());
export const navigate = url => {
    history.pushState(null, null, url)
    trigger();
}
export const onNavigate = fn => {
    listeners.push(fn);
    return () => listeners.splice(listeners.indexOf(fn), 1);
}

window.addEventListener('popstate', trigger);