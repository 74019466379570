import { addEventListener, compose, renderChildren, rAF, selectChildren } from './lib';
// import bind from './bind';

const lerp = (a, b, t) => a + (b - a) * t

const wrapNumber = (x, max) => {
    while (x < 0) x += max;
    while (x >= max) x -= max;
    return x;
}

const slide = element => {
    element.addEventListener('update', e => {
        const { offset, key } = e.detail;
        element.style.transform = `translateX(${offset * 100}%)`;
        if (key < 0) element.style.cursor = 'w-resize';
        if (key > 0) element.style.cursor = 'e-resize';
    });
    return selectChildren(element)
}

export default container => {

    const templates = [...container.children];
    templates.forEach(el => container.removeChild(el));

    let position = 0;
    let target = 0;

    let containerWidth, containerLeft;
    const onResize = () => {
        const rect = container.getBoundingClientRect();
        containerWidth = rect.width;
        containerLeft = rect.left;
    }

    const goTo = i => target = i;

    const render = renderChildren({
        container,
        key: item => item.key,
        template: item => {
            const element = templates[item.i].cloneNode(true);
            element.addEventListener('click', () => goTo(item.key));
            return element;
        },
        bind: slide
    })

    const update = () => {

        position = lerp(position, target, .1);

        const items = [{
            key: 0,
            x: containerLeft - containerWidth * position
        }]
        while (items[0].x > 0) {
            items.unshift({
                key: items[0].key - 1,
                x: items[0].x - containerWidth
            })
        }
        while (items[items.length - 1].x + containerWidth < window.innerWidth) {
            items.push({
                key: items[items.length - 1].key + 1,
                x: items[items.length - 1].x + containerWidth
            })
        }

        for (const item of items) {
            item.i = wrapNumber(item.key, templates.length);
            item.offset = (item.x - containerLeft) / containerWidth;
        }

        render(items);

    }

    onResize()
    update();

    return compose(
        rAF(update),
        addEventListener(window, 'resize', onResize),
        () => render([]),
    )

}