import { createRegistry, compose, selectChildren } from './lib';
import { onNavigate } from '../router';
import assert from 'assert';

const hashless = url => url.split('#')[0];

const debug = false;
const log = (...args) => debug && console.log(...args);

const transitionOutRegistry = createRegistry('transition:transitionOut');
export const registerTransitionOut = transitionOutRegistry.register;

const beforeRemoveRegistry = createRegistry('transition:beforeRemove');
export const registerBeforeRemove = beforeRemoveRegistry.register;

const preloadRegistry = createRegistry('transition:preload');
export const registerPreload = preloadRegistry.register;

const transitionInRegistry = createRegistry('transition:transitionIn');
export const registerTransitionIn = transitionInRegistry.register;

const bindPage = element => {
    const transitionsOut = transitionOutRegistry.provide(element);
    const beforeRemoves = beforeRemoveRegistry.provide(element);
    const preloads = preloadRegistry.provide(element);
    const transitionsIn = transitionInRegistry.provide(element);
    return compose(
        registerTransitionOut(element,
            () => {
                element.style.transform = `translateY(${-window.pageYOffset}px)`;
                element.style.position = 'fixed';
                window.scrollTo({ top: 0 });
                return Promise.all([...transitionsOut.values()].map(f => f()));
            }
        ),
        registerBeforeRemove(element,
            () => Promise.all([...beforeRemoves.values()].map(f => f()))
        ),
        registerPreload(element,
            () => Promise.all([...preloads.values()].map(f => f()))
        ),
        registerTransitionIn(element,
            () => Promise.all([...transitionsIn.values()].map(f => f()))
        ),
        selectChildren(element)
    )
}

const parser = new DOMParser();

// simultaneous:
// active               unloaded
// transitioning out    fetching
//                      fetched
//                      (append)
//                      preloading
// transitioned out     preloaded
// removing             transitioning in
// (remove)             active

// discrete:
//                      unloaded
//                      fetching
//                      fetched
//                      (append)
//                      preloading
// active               preloaded
// 
// transitioned out     
// removing             transitioning in
// (remove)             ...

const states = {
    unloaded: 0,
    fetching: 1,
    fetched: 2,
    appended: 3,
    preloading: 4,
    preloaded: 5,
    transitioningIn: 6,
    active: 7,
    transitioningOut: 8,
    transitionedOut: 9,
    removing: 10,
    removed: 11
}
const stateName = x => Object.keys(states).find(key => states[key] === x);

const syncSimultaneous = [
    [states.active, states.unloaded],
    [states.transitionedOut, states.preloaded],
    [states.transitionedOut, states.active],
    [states.removed, states.active]
]
const syncDiscrete = [
    [states.active, states.unloaded],
    [states.active, states.preloaded],
    [states.transitionedOut, states.preloaded],
    [states.removed, states.active]
]
const findIndexRight = (arr, fn) => {
    for (var i = arr.length - 1; i >= 0; i--) if (fn(arr[i])) return i;
    return -1;
}
const getSyncStep = (sync, state0, state1) => {
    return findIndexRight(sync, pair => pair[0] === state0 && pair[1] === state1)
}

export default ({ pageSelector, mode = "simultaneous" }) => element => {

    const sync = mode === "simultaneous" ? syncSimultaneous : syncDiscrete;

    const transitionsOut = transitionOutRegistry.provide(element);
    const beforeRemoves = beforeRemoveRegistry.provide(element);
    const preloads = preloadRegistry.provide(element);
    const transitionsIn = transitionInRegistry.provide(element);

    const pages = [];

    const initialURL = location.href;
    const initialPage = element.querySelector(pageSelector);
    element.removeChild(initialPage);

    const stateTransition = (fromState, toState, action) => page => {
        assert.ok(page.state === fromState, `Expected page.state to be ${stateName(fromState)} but it was ${stateName(page.state)}`)
        if (action) action(page);
        log(`${stateName(fromState)} -> ${stateName(toState)} (${page.url})`);
        page.state = toState;
    }

    const asyncStateTranstion = (fromState, duringState, toState, action) => {
        const begin = stateTransition(fromState, duringState);
        const done = stateTransition(duringState, toState);
        return async page => {
            begin(page);
            await action(page);
            done(page);
        }
    }

    const fetchPage = asyncStateTranstion(
        states.unloaded, states.fetching, states.fetched,
        async page => {
            if (page.url === initialURL) {
                page.element = initialPage.cloneNode(true);
            } else {
                const res = await fetch(page.url);
                const html = await res.text();
                const doc = parser.parseFromString(html, 'text/html');
                page.element = doc.querySelector(pageSelector);
                console.log(res);
                // assert.ok(page.element, `Problem fetching page: ${page.url}`)
                if (page.element === null) {
                    page.element = document.createElement('div');
                    page.element.innerText = res.statusText;
                }
            }
        }
    )

    const preloadPage = asyncStateTranstion(
        states.fetched, states.preloading, states.preloaded,
        page => {
            element.appendChild(page.element);
            page.cleanup = bindPage(page.element);
            return preloads.get(page.element)();
        }
    )

    const transitionInPage = asyncStateTranstion(
        states.preloaded, states.transitioningIn, states.active,
        page => transitionsIn.get(page.element)()
    )

    const transitionOutPage = asyncStateTranstion(
        states.active, states.transitioningOut, states.transitionedOut,
        page => transitionsOut.get(page.element)()
    )

    const removePage = asyncStateTranstion(
        states.transitionedOut, states.removing, states.removed,
        page => {
            // don't return; don't delay next transition
            beforeRemoves.get(page.element)().then(() => {
                page.cleanup();
                element.removeChild(page.element);
            })
        }
    )

    const next = page => {
        switch (page.state) {
            case states.unloaded: return fetchPage(page);
            case states.fetched: return preloadPage(page);
            case states.preloaded: return transitionInPage(page);
            case states.active: return transitionOutPage(page);
            case states.transitionedOut: return removePage(page);
            default: assert.fail(`Can't advance state from ${stateName(page.state)}`);
        }
    }

    const nextUntil = async (page, state) => {
        while (page.state < state) await next(page);
    }

    let running = false;
    const transitionStep = async () => {
        assert.ok(!running, 'Transition already in progress')
        running = true;
        assert
        const page0 = pages.length === 1 ? null : pages[0];
        const page1 = pages.length === 1 ? pages[0] : pages[1]
        const syncStep = page0
            ? getSyncStep(sync, page0.state, page1.state)
            : findIndexRight(sync, pair => pair[1] === page1.state);
        assert.ok(syncStep > -1, `Pages out of sync`);
        log(`Synced: ${page0 ? stateName(sync[syncStep][0]) : 'none'}, ${stateName(sync[syncStep][1])}`)
        if (syncStep === sync.length - 1) {
            if (page0) {
                pages.shift();
                log(`Removed page, ${pages.length} remain`);
                running = false;
                return transitionStep();
            } else {
                log(`Done`);
                running = false;
            }
        } else {
            const [nextState0, nextState1] = sync[syncStep + 1];
            await Promise.all([
                page0 && nextUntil(page0, nextState0),
                nextUntil(page1, nextState1)
            ])
            running = false;
            return transitionStep();
        }
    }

    const navigate = () => {
        const url = hashless(location.href);
        if (pages.length && pages[pages.length - 1].url === url) return;
        if (pages.length && pages[pages.length - 1].state === states.unloaded) pages.pop();
        pages.push({ url, element: null, cleanup: null, state: states.unloaded });
        if (!running) transitionStep();
    }

    navigate();

    return compose(
        () => pages.forEach(page => page.cleanup && page.cleanup()),
        onNavigate(navigate)
    )
}