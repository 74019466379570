import { selectChildren } from './lib';
import { navigate } from '../router';

export default element => {
    element.addEventListener('click', e => {
        const url = new URL(element.href);
        if (element.target !== "" || element.getAttribute('download') !== null || url.host !== location.host) return;
        if (url.pathname === location.pathname && url.hash) return;
        e.preventDefault();
        navigate(element.href);
    })
    return selectChildren(element);
}