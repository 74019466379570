import { compose } from './lib';
import { registerTransitionOut, registerBeforeRemove } from './transition';
import wait from '~/utils/wait';

export default element => {
    return compose(
        registerTransitionOut(element, () => {
            if (location.href !== element.dataset.project) {
                element.style.opacity = 0;
                return wait(1500);
            }
        }),
        registerBeforeRemove(element, () => {
            if (location.href === element.dataset.project) {
                element.style.opacity = 0;
                return wait(1500);
            }
        })
    )
}