import { registerTransitionOut } from './transition';
import { compose, selectChildren } from './lib';
// import bind from './bind';

export default element => compose(
    registerTransitionOut(element, () => {
        element.style.transform = `translateY(${-window.pageYOffset}px)`;
        element.style.position = 'fixed';
        window.scrollTo({ top: 0 });
    }),
    selectChildren(element)
)