import { compose, addEventListener, rAF, selectChildren } from './lib';
import random from 'lodash/random';

import { state as menuState } from './nav';

const base = document.querySelector('base').href;

const length = v => Math.sqrt(v[0] ** 2 + v[1] ** 2)

export default element => {

    element.classList.remove('opacity-0')

    let radius = 0;
    let maxRadius = 0;
    let collide = true;
    let position = [0, 0];
    const angle = (random(0, 3) * Math.PI / 2) + Math.PI / 4 + random(Math.PI / -8, Math.PI / 8, true);
    let direction = [Math.cos(angle), Math.sin(angle)];
    const maxSpeed = Math.min(window.innerHeight, window.innerWidth) * 0.00025;
    const initialSpeed = random(maxSpeed / 2, maxSpeed, true);
    const exitSpeed = initialSpeed * 10;
    let speed = initialSpeed;

    const updateRadius = () => {
        radius = element.offsetWidth / 2;
        maxRadius = Math.sqrt(radius ** 2 + radius ** 2)
    }
    updateRadius();

    const center = [window.innerWidth / 2, window.innerHeight / 2];
    const safeRadius = length(center) + maxRadius;
    const startAngle = Math.random() * Math.PI * 2
    position = [
        center[0] + Math.sin(startAngle) * safeRadius,
        center[1] + Math.cos(startAngle) * safeRadius
    ];

    const isOffScreen = () => (
        position[0] < -maxRadius ||
        position[1] < -maxRadius ||
        position[0] > window.innerWidth + maxRadius ||
        position[1] > window.innerHeight + maxRadius
    )

    const update = dT => {
        const url = location.href.replace(location.hash, "");
        if (menuState.isOpen) {
            speed = exitSpeed;
            collide = false;
        } else if (url === base) {
            speed = initialSpeed;
            collide = true;
        } else if (url === element.dataset.project) {
            speed = initialSpeed;
            collide = false;
        } else {
            speed = exitSpeed;
            collide = false;
        }

        if (!collide && isOffScreen()) {
            speed = 0;
        }

        if (collide) {
            if (position[0] - radius <= 0) {
                direction[0] = Math.abs(direction[0]);
            }
            if (position[1] - radius <= 0) {
                direction[1] = Math.abs(direction[1]);
            }
            if (position[0] + radius >= window.innerWidth) {
                direction[0] = -Math.abs(direction[0]);
            }
            if (position[1] + radius >= window.innerHeight) {
                direction[1] = -Math.abs(direction[1]);
            }
        }

        position[0] += direction[0] * speed * dT;
        position[1] += direction[1] * speed * dT;
    }
    const render = () => {
        element.style.transform = `
            translate(${ position[0]}px, ${position[1]}px)
            translate(-50%, -50%)
        `
    }

    let then = Date.now();
    const tick = () => {
        const now = Date.now();
        update(Math.min(now - then, 100));
        render();
        then = now;
    }
    tick();

    element.querySelector('img').addEventListener('click', e => {
        e.target.classList.add('pulse')
    })

    return compose(
        selectChildren(element),
        addEventListener(window, 'resize', updateRadius),
        rAF(tick)
    )

}