import { compose } from './utils';

const selectors = {};
export const addSelectors = newSelectors => Object.assign(selectors, newSelectors);

export const select = container => {
    const cleanupFunctions = [];
    const traverse = element => {
        const matches = Object.keys(selectors).filter(s => element.matches(s));
        if (matches.length > 1) {
            throw new Error(`select: Element matched multiple selectors: ${matches.join(', ')}`);
        } else if (matches.length === 1) {
            const selector = matches[0];
            const bind = selectors[selector];
            cleanupFunctions.push(bind(element))
        } else {
            for (const child of element.children) traverse(child);
        }
    }
    traverse(container);
    return compose(...cleanupFunctions);
}

export const selectChildren = element => compose(...[...element.children].map(select))