import { compose, selectChildren } from './lib';
import { onNavigate } from '../router'

export const state = {
    isOpen: false
}

export default nav => {
    const menu = nav.querySelector('ul');
    const show = () => {
        menu.classList.remove('-translate-y-full');
        state.isOpen = true;
    }
    const hide = () => {
        menu.classList.add('-translate-y-full');
        state.isOpen = false;
    }
    const toggle = () => state.isOpen ? hide() : show()
    nav.querySelector('button').addEventListener('click', toggle);
    return compose(
        onNavigate(hide),
        selectChildren(nav)
    )
}