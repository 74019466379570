import ResizeObserver from 'resize-observer-polyfill';
import createObserver from '../utils/observer';

const { observe, unobserve } = createObserver(ResizeObserver);

export default img => {
	const update = width => img.setAttribute('sizes', width + 'px');
	update(img.getBoundingClientRect().width);
	img.setAttribute('srcset', img.dataset.srcset);
	const onSizeChange = entry => update(entry.contentRect.width)
	observe(img, onSizeChange);
	return () => unobserve(img, onSizeChange)
}